<template>
  <div class="m-menu">
    <div class="m-mega-menu">
      <div class="m-mega-menu__content">
        <div class="m-mega-menu__menu">
          <div
            class="m-mega-menu-section"
            v-for="category in categories"
            :key="category.id"
            :title="category.name"
          >
            <SfButton class="sf-menu-item m-mega-menu-section__header sf-button--pure" @click="toggleActiveCategory(category)">
              <span class="sf-menu-item__label">{{ category.name }}</span>
              <span class="sf-menu-item__count" />
              <SfIcon :icon="activeCategoryId !== category.id ? 'plus' : 'minus'" size="xxs" />
            </SfButton>
            <transition name="slide">
              <div class="m-mega-menu-section__content" v-show="category.id === activeCategoryId">
                <SfList>
                  <SfListItem
                    v-for="item in category.items"
                    :key="item.id"
                  >
                    <router-link
                      :to="item.link"
                      @click.native="$emit('close')"
                    >
                      <SfMenuItem :label="item.name">
                        <template #mobile-nav-icon="{icon}">
                          <SfIcon :icon="[]" size="xxs" />
                        </template>
                      </SfMenuItem>
                    </router-link>
                  </SfListItem>
                </SfList>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { isServer } from '@vue-storefront/core/helpers'
import SfButton from '@storefront-ui/vue/src/components/atoms/SfButton/SfButton.vue';
import SfIcon from '@storefront-ui/vue/src/components/atoms/SfIcon/SfIcon.vue';
import SfList from '@storefront-ui/vue/src/components/organisms/SfList/SfList.vue';
import SfMenuItem from '@storefront-ui/vue/src/components/molecules/SfMenuItem/SfMenuItem.vue';

import { prepareCategoryMenuItem } from '~/theme/helpers';
import { ModalList } from '~/theme/store/ui/modals';

export default {
  components: {
    SfIcon,
    SfList,
    SfMenuItem,
    SfButton
  },
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    },
    categoriesIds: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      activeCategoryId: null
    }
  },
  computed: {
    ...mapGetters({
      getCategories: 'category/getCategories',
      getCurrentCategory: 'category/getCurrentCategory',
      isLoggedIn: 'user/isLoggedIn'
    }),
    categories () {
      return this.categoriesIds
        .map(({ id, children_data: childrenData = [] }) => {
          const subCategories = childrenData
            .map(subCategory => prepareCategoryMenuItem(
              this.getCategories.find(category => category.id === subCategory.id)
            ))
            .filter(Boolean)
            .sort((a, b) => a.position - b.position)

          const category = this.getCategories.find(category => category.id === id)
          return {
            ...prepareCategoryMenuItem(category),
            items: [...subCategories]
          };
        })
        .sort((a, b) => a.position - b.position);
    },
    currentCategoryTitle () {
      return this.getCurrentCategory.name || ''
    }
  },
  methods: {
    ...mapActions('ui', {
      openModal: 'openModal'
    }),
    toggleActiveCategory (category) {
      if (this.activeCategoryId !== category.id) {
        this.activeCategoryId = category.id
        return
      }
      this.activeCategoryId = null
    },
    goToAccount () {
      if (this.isLoggedIn) {
        this.$router.push(this.localizedRoute('/my-account'))
      } else {
        this.$emit('close')
        this.openModal({ name: ModalList.Auth, payload: 'login' })
      }
    }
  },
  mounted () {
    if (!isServer) {
      this.toggleActiveCategory(this.categories[0])
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers";

::v-deep .m-mega-menu {
  padding: var(--spacer-sm) 0;
  @include for-desktop {
    padding: var(--spacer-xl) 0 var(--spacer-sm);
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 var(--spacer-sm);
    @include for-desktop {
      padding: 0 var(--spacer-base);
    }
    .sf-menu-item {
      padding: var(--spacer-xs) 0;
      outline: none;
      border-bottom: 1px solid var(--c-light);
    }
  }
  &__menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1;
    width: 100%;
  }
}

::v-deep .m-mega-menu-section {
  flex: 1;
  width: 100%;
  .sf-button {
    font-weight: var(--font-weight--bold);
  }
  &__content {
    display: block;
    padding-left: var(--spacer-xs);
    padding-bottom: var(--spacer-sm);
    @include for-desktop {
      padding-bottom: var(--spacer-base);
    }
    .sf-button {
      font-family: var(--font-family--primary);
      font-weight: var(--font-weight--normal);
    }
    .sf-menu-item {
      font-size: var(--font-size--sm);
    }
  }
}

</style>
