<template>
  <ProteinSfSidebar
    :visible="isSidebarOpen && isSidebarVisible"
    class="sf-sidebar--left sf-sidebar--icon o-sidebar-panel"
    :persistent="isMobile"
    @close="closeSidebarExtend"
  >
    <template #bar>
      <div />
    </template>
    <SfOverlay :visible="isSidebarOpen && isSearchPanelVisible" @click="$store.commit('ui/setSearchpanel', false)" />
    <div :style="isSearchPanelVisible ? 'overflow-y: hidden' : undefined">
      <OSearch ref="search" in-sidebar />
      <MMenu
        :categories-ids="categories"
        @close="closeSidebarExtend"
      />
      <div class="o-secondary-menu" v-html="secondaryMenu" />
      <div class="o-secondary-menu language-switcher smartphone-only">
        <ALanguageSwitcherIcon />
      </div>
    </div>
  </ProteinSfSidebar>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import VueOfflineMixin from 'vue-offline/mixin';
import onEscapePress from '@vue-storefront/core/mixins/onEscapePress';
import { formatCategoryLink } from '@vue-storefront/core/modules/url/helpers';
import { Search } from '@vue-storefront/core/modules/catalog/components/Search';
import SfOverlay from '@storefront-ui/vue/src/components/atoms/SfOverlay/SfOverlay.vue';
import ProteinSfSidebar from '~/theme/components/organisms/ProteinSfSidebar/ProteinSfSidebar.vue';
import MMenu from '~/theme/components/molecules/m-menu';
import OSearch from '~/theme/components/organisms/o-search';
import ALanguageSwitcherIcon from '~/theme/components/atoms/a-language-switcher-icon';
import { getTopLevelCategories } from '~/theme/helpers';
import { mapMobileObserver, unMapMobileObserver } from '~/theme/helpers/mobile-observer';

export default {
  components: {
    ProteinSfSidebar,
    SfOverlay,
    OSearch,
    MMenu,
    ALanguageSwitcherIcon
  },
  mixins: [VueOfflineMixin, Search, onEscapePress],
  data () {
    return {
      isSidebarVisible: false,
      search: ''
    };
  },
  computed: {
    ...mapState({
      isSidebarOpen: state => state.ui.sidebar,
      shouldSearchFocus: state => state.ui.sidebarSearchFocus,
      isSearchPanelVisible: state => state.ui.searchpanel
    }),
    ...mapGetters({
      getCategories: 'category/getCategories',
      getCurrentCategory: 'category/getCurrentCategory',
      cmsBlocks: 'cmsBlock/getCmsBlocks'
    }),
    categories () {
      return getTopLevelCategories(this.getCategories);
    },
    secondaryMenu () {
      const menu = this.cmsBlocks.find((_) => _.identifier === 'secondary_menu') || {}
      return menu.content || ''
    },
    ...mapMobileObserver()
  },
  watch: {
    isSidebarOpen: {
      immediate: true,
      handler (val) {
        !val && this.closeSidebarExtend()
      }
    },
    shouldSearchFocus: {
      immediate: true,
      handler (val) {
        val && this.focusSearchBar()
      }
    }
  },
  methods: {
    focusSearchBar () {
      this.$nextTick(() => {
        const searchInput = this.$refs.search?.$el?.firstElementChild?.firstElementChild
        if (searchInput && searchInput.type === 'search') {
          searchInput.focus()
          this.$store.commit('ui/setSearchpanel', true)
        }
      })
    },
    startSearch () {
      if (this.search.length >= 3) {
        this.makeSearch();
      }
    },
    categoryLink (category) {
      return formatCategoryLink(category);
    },
    isCategoryActive (category) {
      return this.getCurrentCategory.path ? this.getCurrentCategory.path.startsWith(category.path) : false;
    },
    onEscapePress () {
      this.closeSidebarExtend();
    },
    closeSidebarExtend () {
      this.$store.dispatch('ui/toggleSidebar', false)
    },
    fetchMenuData () {
      return this.$store.dispatch('category/list', { skipCache: true }, { root: true })
    }
  },
  mounted () {
    this.isSidebarVisible = true;
  },
  beforeDestroy () {
    unMapMobileObserver();
  },
  serverPrefetch () {
    return Promise.all([
      this.fetchMenuData()
    ]);
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

::v-deep .sf-header__search {
  --search-bar-display: flex;
  --search-bar-border: solid var(--c-light);
  --search-bar-placeholder-color: var(--c-text-primary);

  margin: var(--spacer-xs) var(--spacer-sm) var(--spacer-xs);
  @include for-desktop {
    --search-bar-width: 25rem;
    margin: var(--spacer-xs);
  }
  .sf-search-bar__input {
    margin-right: var(--spacer-base);
  }
}

::v-deep .sf-sidebar {
  &__search {
    margin: var(--spacer-sm);
  }
  &__content {
    --sidebar-content-padding: 0;
    @include for-desktop {
      --sidebar-content-padding: 0 var(--spacer-sm) 0 0;
    }
  }
  &__aside {
    @include for-mobile {
      --sidebar-box-shadow: none;
      top: var(--header-full-height);
      height: calc(100% - var(--header-full-height));
    }
  }
}

.o-secondary-menu ::v-deep {
  padding: var(--spacer-sm);
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
      list-style: none;
      margin: 0 0 var(--spacer-sm);
      &.hw {
        width: calc(50% - var(--spacer-xs));
      }
      &.fw {
        width: 100%;
      }
      &.contact {
        margin-top: var(--spacer-sm);
        text-align: center;
        font-family: var(--font-family--primary);
        font-size: calc(var(--font-size--lg) * 1.2);
        [href*=mailto] {
          font-weight: bold;
        }
      }
    }
  }
}

.o-secondary-menu .language-switcher {
  padding-top: 0
}

::v-deep .sf-overlay {
    @include for-mobile {
      top: calc(var(--header-full-height) + 3rem);
    }
}
</style>
